const TextInputRegister = ({
  label,
  name,
  register,
  errors,
  password = null,
  type = "text",
}) => {
  const handleError = () => {
    switch (name) {
      case "name":
        return "El Nombre es requerido";
      case "fatherLastName":
        return "El Apellido Paterno es requerido";
      case "motherLastName":
        return "El Apellido Materno es requerido";
      case "professionalID":
        return "La Cédula es requerida";
      case "phone":
        return "El Teléfono es requerido";
      case "email":
        return "El Email es requerido";
      case "confirmEmail":
        return "La Confirmación del Email debe ser igual al Email";
      case "password":
        return "La Contraseña es requerida";
      case "confirmPassword":
        return "La Confirmación de la Contraseña debe ser igual a la Contraseña";
    }
  };

  return (
    <>
      <label htmlFor={name} className="text-sm text-gray-600 mb-2 block">
        {label}
      </label>

      <input
        type={`${password ? "password" : type}`}
        name={name}
        id={name}
        className="w-full py-2 px-4 shadow-sm focus:ring-deep-sky-blue focus:border-deep-sky-blue border-gray-300 rounded-lg appearance-textfield"
        ref={register}
      />
      {errors[name]?.message && (
        <p className="text-xs text-red-400 pl-5 mt-1">{handleError()}</p>
      )}
    </>
  );
};

export default TextInputRegister;
