import * as yup from "yup";

const registerSchema = (inputs) => {
  let objectSchema = {};

  inputs.forEach((input) => {
    if (input.type === "String" || input.type === "Password") {
      if (input.name === "confirmNewPassword") {
        return (objectSchema[input.name] = yup
          .string()
          .oneOf([yup.ref("newPassword")]));
      } else if (input.name === "confirmPassword") {
        return (objectSchema[input.name] = yup
          .string()
          .oneOf([yup.ref("password")]));
      } else {
        return (objectSchema[input.name] = yup.string().required());
      }
    } else if (input.type === "Email") {
      if (input.name === "confirmEmail") {
        return (objectSchema[input.name] = yup
          .string()
          .email()
          .trim()
          .oneOf([yup.ref("email")]));
      } else {
        return (objectSchema[input.name] = yup
          .string()
          .email()
          .required()
          .trim());
      }
    } else if (input.type === "Number") {
      return (objectSchema[input.name] = yup.number().required());
    } else if (input.type === "Boolean") {
      return (objectSchema[input.name] = yup.boolean().required().isTrue());
    } else if (input.type === "Select") {
      return (objectSchema[input.name] = yup
        .mixed()
        .notOneOf(input.options)
        .required());
    }
  });

  return yup.object().shape(objectSchema);
};

export default registerSchema;
